import './App.css';
import { Canvas } from '@react-three/fiber';
import { Experience } from './components/Experience';
import * as THREE from 'three';

function App() {
  return (
    <Canvas
      camera={{
        fov: 64,
        position: [2.3, 1.5, 2.3],
      }}
      gl={{ antialias: true }}
      onCreated={({ gl }) => {
        gl.toneMapping = THREE.LinearToneMapping;
        gl.toneMappingExposure = 2.4;
      }}
    >
      <Experience />
    </Canvas>
  );
}

export default App;
